import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { PrismicState } from '../types/PrismicState'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import Prismic from 'prismic-javascript'
import config from 'config'
import { blockProcessor, componentProcessor } from '../utilities'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { productQuery } from '../queries'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'


// Used to simulate a product header as it would be returned by Prismic
// The goal is to provide a fallback to product pages when the content hasn't been contributed yet on Prismic
const getElasticProductAsProductHeader = (uid) => {
  const storeView = currentStoreView()
  return quickSearchByQuery({ query: productQuery(uid), entityType: 'product', storeCode: storeView.storeCode })
    .then(({ items }) => items[0])
    .then(item => {
      return {
        content: [
          {
            slice_type: 'product_header',
            slice_label: null,
            primary: {
              block_tag: null
            }
          }
        ],
        product: {
          product: {
            [storeView.storeCode]: item
          }
        }
      }
    })
}

/**
 *
 * Get product by sku, we need this for the warranty products (the warranty db contains the skus, not the ids)
 * @param sku prouct sku
 * @returns product search
 */
export const getElasticProductByTerms = (sku) => {
  const query = new SearchQuery()
  query.setSearchText(sku)
  query
    .applyFilter({ key: 'visibility', value: { in: [2, 3, 4] } })
    .applyFilter({ key: 'status', value: { in: [1] } })

  const storeView = currentStoreView()
  return quickSearchByQuery({ query, entityType: 'product', storeCode: storeView.storeCode })
    .then(({ items }) => items[0])
    .then(item => {
      return {
        product: {
          [storeView.storeCode]: item
        }
      }
    })
}

export const getElasticProduct = (uid) => {
  const storeView = currentStoreView()
  return quickSearchByQuery({ query: productQuery(uid), entityType: 'product', storeCode: storeView.storeCode })
    .then(({ items }) => items[0])
    .then(item => {
      return {
        product: {
          [storeView.storeCode]: item
        }
      }
    })
}

const mapSliders = (resolved) => {
  resolved.content = resolved.content || []
  const indexes = resolved.content
    .map(({ slice_type }, index) => slice_type === 'product_slider' ? index : false)
    .filter(value => value !== false)

  const sliderData = resolved.content
    .filter(({ slice_type }) => slice_type === 'product_slider')
    .map(slice => ({
      ...slice,
      items: slice.items
        .map((item) => ({ ...item, block_product: item.block_product && item.block_product.data && item.block_product.data.product ? item.block_product.data.product : {} }))
    }))

  const sliderUids = resolved.content
    .filter(({ slice_type }) => slice_type === 'product_slider')
    .map(slice => ({ uids: slice.items.map(({ block_product }) => ({ uid: block_product.uid })) }))

  const sliders = sliderData.map((slider, index) => ({ ...slider, items: slider.items.map((item, i) => ({ ...item, uid: sliderUids[index].uids[i].uid })) }))

  indexes.forEach((index, i) => {
    resolved.content[index] = sliders[i]
  })

  return resolved
}

export const actions: ActionTree<PrismicState, any> = {
  // flow: get prismic content -> data mapping -> commit to the vuex state
  setPage({ commit, state }, { uid, type, size, offset }) {

    size = size || 10
    offset = offset || 1

    // Check whether the content has been queried already on the cms and is in memory cache
    const uidAndTypeAlreadyQueried = state.pages[`${type}-${uid}`]
    if (uidAndTypeAlreadyQueried) {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    }

    const storeView = currentStoreView()
    const buildOptions = (_offset, _size, _additionnalOptions) => ({
      lang: storeView.i18n.defaultLocale.toLowerCase(),
      fetchLinks: ['product.product', 'product.uid', 'download.body'],
      page: _offset,
      pageSize: _size,
      ..._additionnalOptions
    })

    let additionnalOptions: any = {}
    // Order news by user contributed date field if the news list is queried
    if (!uid && type === 'news') {
      additionnalOptions.orderings = '[my.news.date desc, my.news.title desc]'
    }
    // Query previous and next page if a single news is queried

    if (type === 'news-landing' && uid === 'news-landing') {
      additionnalOptions.graphQuery = `{
        news-landing {
          ...news-landingFields
          content {
            ...on headline_title_text {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on news_promote {
              non-repeat {
                news_1 {
                  ...on news {
                    ...newsFields
                  }
                }
                news_2 {
                  ...on news {
                    ...newsFields
                  }
                }
                news_3 {
                  ...on news {
                    ...newsFields
                  }
                }
              }
            }
            ...on category_block {
              repeat {
                ...repeatFields
                category_field {
                 ...on news-category {
                   ...news-categoryFields
                   content {
                     ...on category_block {
                       non-repeat {
                         block_title
                      }
                      repeat {
                        ...repeatFields
                        page_brand {
                          ...on brand {
                            ...brandFields
                          }
                        }
                        news_a {
                          ...on news {
                            ...newsFields
                          }
                        }
                        
                        news_c {
                          ...on news {
                            ...newsFields
                          }
                        }
                        
                        news_b {
                        ...on news {
                            ...newsFields

                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    }

    if (type === 'brand' && uid === 'time-to-reach-your-sta' || uid === 'time-to-reach-your-star') {
      additionnalOptions.graphQuery = `{
        brand {
          ...brandFields
          content {
            ...on slider_tabs_bar {
              repeat {
                ...repeatFields
                details_link {
                  ...on la-maison-category {
                    ...la-maison-categoryFields
                    content {
                      ...on categorie {
                        repeat {
                          ...repeatFields
                          block_brand_page {
                            ... on brand {
                              ...brandFields
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ...on hero_static_banner {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on title_image {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on title_text_image {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on cta {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on fullscreen_image_banner {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on basic_page_title {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on video_gif {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on quote {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on headline_title_text {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on title_text {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on title_text_center {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on edito_text_image {
              non-repeat {
                ...non-repeatFields
              }
            }
            ...on slider_tabs_bar {
              repeat {
                ...repeatFields
                details_link {
                  ...on la-maison-category {
                    ...la-maison-categoryFields
                    content {
                      ...on categorie {
                        non-repeat {
                          ...non-repeatFields
                        }
                        repeat {
                          ...repeatFields
                          block_brand_page {
                            ... on brand {
                              ...brandFields
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    }

    const request = (options, noUid) => Prismic
      .api(config.prismic.api)
      .then(api => api.query(uid && !noUid ?
        [
          Prismic.Predicates.at('document.type', type),
          Prismic.Predicates.at(`my.${type}.uid`, uid)
        ] :
        [
          Prismic.Predicates.at('document.type', type)
        ], options))


    return request(buildOptions(offset, size, additionnalOptions), false)
      .then(({ results, total_results_size }) => {
        if (results.length === 0) {
          if (type === 'product' && uid) {
            return getElasticProductAsProductHeader(uid)
              .then(content => {
                if (content && content.product && content.product.product && !content.product.product[storeView.storeCode]) {
                  // router.push({ path: storeView.url + '/page-not-found' })
                } else {
                  commit(types.SET_PAGE, { uid, type, page: componentProcessor(content) })
                }
              })
            }
            // } else {
              // router.push({ path: storeView.url + '/page-not-found' })
            // }
        }
        if (type === 'news') {
          // avoir cursor issue when page N is accessed in SSR so we clear the news pages when the page changes
          commit(types.CLEAR_PAGES, { type })
        }
        if (results.length && type !== 'product') {
          results
            .forEach(({ alternate_languages, data, uid: uidFromData }) => {
              const mapped = mapSliders(data)
              commit(types.SET_PAGE, { uid: uidFromData, type, page: componentProcessor(mapped) })
              commit(types.SET_ALTERNATE_LANGUAGE, { uid: uidFromData, type, languages: alternate_languages })
            })
          commit(types.SET_TOTAL_RESULTS_SIZE, { type, totalResultsSize: total_results_size })
        }
        if (type === 'product' && uid) {
          return getElasticProduct(uid)
            .then(product => {
              const data = mapSliders(results[0].data)
              results[0].data = data
              results[0].data.product = product
              commit(types.SET_PAGE, { uid, type, page: componentProcessor(results[0].data) })
              commit(types.SET_ALTERNATE_LANGUAGE, { uid, type, languages: results[0].alternate_languages })

            })
        }
        if (uid && type === 'news') {
          const current = results[0]
          const previousOptions = buildOptions(1, 1, {
            orderings: '[my.news.date, my.news.title]',
            after: current.id
          })
          const nextOptions = buildOptions(1, 1, {
            orderings: '[my.news.date desc, my.news.title desc]',
            after: current.id
          })
          return Promise
            .all([
              request(previousOptions, true),
              request(nextOptions, true),
            ])
            .then(([previous, next]) => ({ previous: previous.results.length ? previous.results[0] : null, next: next.results.length ? next.results[0] : null }))
            .then(({ previous, next }) => {
              const news = state.pages[`${type}-${uid}`]
              const aggregated = { ...news, main: { ...news.main, previous: previous ? { uid: previous.uid, title: previous.data.title } : null, next: next ? { uid: next.uid, title: next.data.title } : null } }
              commit(types.SET_PAGE, { uid, type, page: aggregated })
              commit(types.SET_ALTERNATE_LANGUAGE, { uid, type, languages: results[0].alternate_languages })
            })
        }
      })
      .catch(error => console.error(error))
  },
  setBlock({ commit, state }, uid) {

    const uidAlreadyQueried = state.blocks[uid]
    if (uidAlreadyQueried) {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    }

    const storeView = currentStoreView()
    return Prismic
      .api(config.prismic.api)
      .then(api => api.query([
        Prismic.Predicates.at('document.type', uid),
      ], { lang: storeView.i18n.defaultLocale.toLowerCase() }))
      .then(response => response.results.map(result => result.data)[0])
      .then(block => commit(types.SET_BLOCK, { uid, block: blockProcessor(uid, block) }))
      .catch(error => console.log(error))
  },
  search({ commit }, { type, searchText }) {
    const storeView = currentStoreView()
    const request = (offset, previousResults) => Prismic
      .api(config.prismic.api)
      .then(api => api.query([
        Prismic.Predicates.at('document.type', type),
        Prismic.Predicates.fulltext('document', searchText)
      ], { lang: storeView.i18n.defaultLocale.toLowerCase(), page: offset, pageSize: 100 }))
      .then(({ results, total_pages }) => {
        if (total_pages > offset * 100) {
          // NOTE: Recursive: Over time it maybe cause problems depending on the length of the server response
          return request(offset + 1, [...previousResults, ...results])
        } else {
          return [...previousResults, ...results]
        }
      })
    return request(1, [])
  }
}
