import { GetterTree } from 'vuex'
import isNil from 'lodash-es/isNil'

export const getters: GetterTree<any, any> = {
  // todo : spec are:
  // if country = US -> exclude tax
  // else (country != us or store = globale) show price with taxes
  getSubTotalExcludeTax: state => {
    if (!state.cartItems.length) return 0
    return state.cartItems.reduce((acc, curr) => {
      if (curr.product.deposit_amount) {
        acc += curr.qty * +curr.product.deposit_amount
      } else {
        let priceToAdd = isNil(curr.price) ? curr.product.price : curr.price
        acc += +curr.qty * +priceToAdd
      }

      return acc
    }, 0)
  }
}
